import GridTextCell from '@/components/gridView/GridTextCell.vue'
import GridIntegerCell from '@/components/gridView/GridIntegerCell.vue'
import GridDecimalCell from '@/components/gridView/GridDecimalCell.vue'
import GridDateAndTimeCell from '@/components/gridView/GridDateAndTimeCell.vue'
import GridDateCell from '@/components/gridView/GridDateCell.vue'
import GridBooleanCell from '@/components/gridView/GridBooleanCell.vue'
import GridEnumCell from '@/components/gridView/GridEnumCell.vue'
import GridCrossReferenceCell from '@/components/gridView/GridCrossReferenceCell.vue'
import GridAttachmentCell from '@/components/gridView/GridAttachmentCell.vue'
import GridGeolocationCell from '@/components/gridView/GridGeolocationCell.vue'
import GridAddressCell from '@/components/gridView/GridAddressCell.vue'
import CreatedByCell from '@/components/gridView/CreatedByCell.vue'
import UserReferenceCell from '@/components/gridView/UserReferenceCell.vue'
import GridCurrencyCell from '@/components/gridView/GridCurrencyCell.vue'
import GridUriCell from '@/components/gridView/GridUriCell.vue'
import GridEmailCell from '@/components/gridView/GridEmailCell.vue'
import GridSignatureCell from '@/components/gridView/GridSignatureCell.vue'
import GridPhoneNumberCell from '@/components/gridView/GridPhoneNumberCell.vue'
import GridFormulaCell from '@/components/gridView/GridFormulaCell.vue'

import RoGridTextCell from '@/components/gridView/RoGridTextCell.vue'
import RoGridBooleanCell from '@/components/gridView/RoGridBooleanCell.vue'
import RoChipsGridCell from '@/components/gridView/RoChipsGridCell.vue'
import RoGridFilesCell from '@/components/gridView/RoGridFilesCell.vue'
import RoGridReferencesCell from '@/components/gridView/RoGridReferencesCell.vue'
import RoGridCurrencyCell from '@/components/gridView/RoGridCurrencyCell.vue'
import RoLinkCell from '@/components/gridView/RoLinkCell.vue'

import BaseKanbanAttribute from '@/components/kanbanView/kanbanCardAttributes/BaseKanbanAttribute.vue'
import KanbanBooleanAttribute from '@/components/kanbanView/kanbanCardAttributes/KanbanBooleanAttribute.vue'
import KanbanEnumAttribute from '@/components/kanbanView/kanbanCardAttributes/KanbanEnumAttribute.vue'
import KanbanEnumCollectionAttribute from '@/components/kanbanView/kanbanCardAttributes/KanbanEnumCollectionAttribute.vue'
import KanbanReferencesAttribute from '@/components/kanbanView/kanbanCardAttributes/KanbanReferencesAttribute.vue'
import KanbanAttachmentsAttribute from '@/components/kanbanView/kanbanCardAttributes/KanbanAttachmentsAttribute.vue'
import KanbanStringAttribute from '@/components/kanbanView/kanbanCardAttributes/KanbanStringAttribute.vue'
import KanbanSignatureAttribute from '@/components/kanbanView/kanbanCardAttributes/KanbanSignatureAttribute.vue'
import KanbanUserReferenceAttribute from '@/components/kanbanView/kanbanCardAttributes/KanbanUserReferenceAttribute.vue'

import CurrencyOptions from '@/components/gridView/CurrencyOptions.vue'
import EnumOptions from '@/components/gridView/EnumOptions.vue'
import EnumCollectionOptions from '@/components/gridView/EnumCollectionOptions.vue'
import CrossReferenceOptions from '@/components/gridView/CrossReferenceOptions.vue'
import LookupOptions from '@/components/gridView/LookupOptions.vue'
import SumupOptions from '@/components/gridView/SumupOptions.vue'
import FormulaOptions from '@/components/gridView/FormulaOptions.vue'

import i18n from '@/plugins/i18n'
import * as Conditions from '@/filter/conditions.js'
import * as Expressions from '@/constants/expressions/index.js'
import { noValue } from '@/utils/jsUtils.js'
import moment from 'moment'
import { SORTING_FUNCTIONS } from '@/sorting/sortingFunctions'
import { DATE_EXPORT_FORMAT, DATE_DISPLAY_FORMAT, DATETIME_DISPLAY_FORMAT } from './dateFormats'
import { isEqual } from 'lodash'
import AGAddress from '@/store/models/AGAddress'
/* global ASDateAndTime */

export const columnTypes = {
  string: {
    name: 'string',
    displayString: i18n.t('columnTypes.text'),
    typeIcon: 'mdi-alphabetical-variant',
    cellComponent: GridTextCell,
    readonlyCellComponent: RoGridTextCell,
    kanbanAttributeComponent: KanbanStringAttribute,
    defaultFormFieldType: 'textfield',
    sortOverride: (a, b) => {
      if (a.toLowerCase() > b.toLowerCase()) return 1
      if (a.toLowerCase() < b.toLowerCase()) return -1
      // When lowercase values are equal, use default sorting to have uppercase before lowercase
      if (a > b) return 1
      if (a < b) return -1
      return 0
    },
    filterConditions: [
      Conditions.AGSubstringCondition,
      Conditions.AGNotSubstringCondition,
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    sanitizedValue: value => value,
    sampleValue: () => ''
  },
  integer: {
    name: 'integer',
    displayString: i18n.t('columnTypes.number'),
    typeIcon: 'mdi-numeric',
    cellComponent: GridIntegerCell,
    readonlyCellComponent: RoGridTextCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    defaultFormFieldType: 'textfield',
    filterConditions: [
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGLessCondition,
      Conditions.AGGreaterCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    sanitizedValue: value => {
      const parsedInt = parseInt(value)
      return isNaN(parsedInt) ? null : parsedInt
    },
    sum: true,
    sampleValue: () => 0
  },
  decimal: {
    name: 'decimal',
    displayString: i18n.t('columnTypes.decimal'),
    typeIcon: 'mdi-decimal',
    cellComponent: GridDecimalCell,
    readonlyCellComponent: RoGridTextCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    defaultFormFieldType: 'textfield',
    filterConditions: [
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGLessCondition,
      Conditions.AGGreaterCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    sanitizedValue: value => {
      const parsedFloat = parseFloat(value)
      return isNaN(parsedFloat) ? null : parsedFloat
    },
    sum: true,
    sampleValue: () => 0
  },
  dateTime: {
    name: 'date-time',
    displayString: i18n.t('columnTypes.dateAndTime'),
    typeIcon: 'mdi-calendar-clock',
    cellComponent: GridDateAndTimeCell,
    readonlyCellComponent: RoGridTextCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    defaultFormFieldType: 'datePicker',
    filterConditions: [
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGLessCondition,
      Conditions.AGGreaterCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    filterConditionDisplayStringOverrides: {
      AGLessCondition: 'virtualGrid.filter.beforeCondition',
      AGGreaterCondition: 'virtualGrid.filter.afterCondition'
    },
    compareOverrides: {
      equals: (left, right) => {
        if (noValue(left) && noValue(right)) return true
        return typeof left === 'string' && moment(left).isSame(moment(right))
      },
      isLessThan: (left, right) => {
        return typeof left === 'string' && moment(left).isBefore(moment(right))
      },
      isGreaterThan: (left, right) => {
        return typeof left === 'string' && moment(left).isAfter(moment(right))
      }
    },
    displayFormat: value => value ? moment(value)?.format(DATETIME_DISPLAY_FORMAT) : '',
    sanitizedValue: value => {
      const aMoment = moment(value)
      if (aMoment.isValid()) {
        return aMoment.toISOString()
      } else {
        return value
      }
    },
    sampleValue: () => new ASDateAndTime()
  },
  date: {
    name: 'date',
    displayString: i18n.t('columnTypes.date'),
    typeIcon: 'mdi-calendar-range-outline',
    cellComponent: GridDateCell,
    readonlyCellComponent: RoGridTextCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    defaultFormFieldType: 'datePicker',
    filterConditions: [
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGLessCondition,
      Conditions.AGGreaterCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    filterExpressions: [
      Expressions.AGValueExpression,
      Expressions.AGToday,
      Expressions.AGTomorrow,
      Expressions.AGYesterday,
      Expressions.AGDaysAgo,
      Expressions.AGDaysFromToday
    ],
    filterConditionDisplayStringOverrides: {
      AGLessCondition: 'virtualGrid.filter.beforeCondition',
      AGGreaterCondition: 'virtualGrid.filter.afterCondition'
    },
    compareOverrides: {
      equals: (left, right) => {
        if (noValue(left) && noValue(right)) return true
        return typeof left === 'string' && moment(left).isSame(moment(right))
      },
      isLessThan: (left, right) => {
        return typeof left === 'string' && moment(left).isBefore(moment(right))
      },
      isGreaterThan: (left, right) => {
        return typeof left === 'string' && moment(left).isAfter(moment(right))
      }
    },
    displayFormat: value => value ? moment(value)?.format(DATE_DISPLAY_FORMAT) : '',
    sanitizedValue: value => {
      const aMoment = moment(value)
      if (aMoment.isValid()) {
        return aMoment.format(DATE_EXPORT_FORMAT)
      } else {
        return value
      }
    },
    sampleValue: () => new Date()
  },
  boolean: {
    name: 'boolean',
    displayString: i18n.t('columnTypes.checkmark'),
    typeIcon: 'mdi-checkbox-marked-outline',
    cellComponent: GridBooleanCell,
    readonlyCellComponent: RoGridBooleanCell,
    kanbanAttributeComponent: KanbanBooleanAttribute,
    defaultFormFieldType: 'checkbox',
    filterConditions: [
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    compareOverrides: {
      equals: (left, right) => {
        // if a value is null or undefined, we default it to false
        return (left ?? false) === (right ?? false)
      }
    },
    displayFormat: value => value,
    sanitizedValue: value => {
      if (value == null) {
        return null
      }
      if (value === true || value === false) {
        return value
      }
      else if ( typeof value === 'string' ) {
          switch(value.toLowerCase().trim()){
            case 'true':
            case 'yes':
            case 'ja':
            case 'wahr':
            case '1':
              return true

            case 'false':
            case 'no':
            case 'nein':
            case 'falsch':
            case '0':
              return false
            default:
              console.error(`${value} is not convertible to boolean type`)
              return null
            }
      }
      else {
        console.error(`${value} is not convertible to boolean type`)
        return null
      }
    },
    sampleValue: () => false
  },
  enum: {
    name: 'enum',
    displayString: i18n.t('columnTypes.singleSelect'),
    typeIcon: 'mdi-arrow-down-drop-circle-outline',
    cellComponent: GridEnumCell,
    readonlyCellComponent: RoChipsGridCell,
    kanbanAttributeComponent: KanbanEnumAttribute,
    defaultFormFieldType: 'selectBox',
    formFieldTypes: ['selectBox', 'selectList'],
    filterConditions: [
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    sanitizedValue: value => value,
    state: {
      component: EnumOptions,
      hasChanged: (type, value) => !isEqual(value, { attributes: [...type.jsonSchema.enum], extended: type.extended}),
      initialisation: (type) => ({ attributes: [...type.jsonSchema.enum], extended: type.extended}),
      defaultValue: () => ({ attributes: [], extended: false }),
      validate: (value) => Array.isArray(value.attributes) && value.attributes.length > 0,
      attributesFrom: (value) => value,
      typePatchPayloadFrom:(value) => ({extended: value.extended, options: value.attributes})
    },
    sampleValue: (type) => type.jsonSchema.enum[0]
  },
  enumCollection: {
    name: 'enumcollection',
    displayString: i18n.t('columnTypes.enumCollection'),
    typeIcon: 'mdi-format-list-bulleted',
    cellComponent: GridEnumCell,
    readonlyCellComponent: RoChipsGridCell,
    kanbanAttributeComponent: KanbanEnumCollectionAttribute,
    defaultFormFieldType: 'multiSelectDropdown',
    formFieldTypes: ['multiSelectDropdown', 'multiSelectList'],
    filterConditions: [
      Conditions.AGAnyCondition,
      Conditions.AGNoneCondition,
      Conditions.AGAllCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    compareOverrides: {
      equals: (left, right) => {
        if (noValue(left) && noValue(right)) {
          return true
        }
        if ((left?.length ?? 0) !== (right?.length ?? 0)) {
          return false
        }
        for (let index = 0; index < left.length; index++) {
          if (left[index] !== right[index]) {
            return false
          }
        }
        return true
      }
    },
    displayFormat: value => value?.join(', '),
    sanitizedValue: value => value,
    state: {
      component: EnumCollectionOptions,
      hasChanged: (type, value) => !isEqual(value, { attributes: [...type.jsonSchema.items.enum]}),
      initialisation: (type) => ({ attributes: [...type.jsonSchema.items.enum]}),
      defaultValue: () => ({ attributes: [], extended: false }),
      validate: (value) => Array.isArray(value.attributes) && value.attributes.length > 0,
      attributesFrom: (value) => value
    },
    sampleValue: (type) => [type.jsonSchema.items.enum[0]]
  },
  crossReference: {
    name: 'reference',
    displayString: i18n.t('columnTypes.crossReference'),
    typeIcon: 'mdi-table-arrow-right',
    cellComponent: GridCrossReferenceCell,
    readonlyCellComponent: RoGridReferencesCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    defaultFormFieldType: 'entitySelect',
    filterConditions: [
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    compareOverrides: {
      equals: (left, right) => {
        // Hack : compare entity id instead of full URI
        // Uri inside of field values are relative
        // to the parent persistent Grid instead of the field referenced virtual Grid
        const leftId = left?.uri?.split('/').pop()
        const rightId = right?.uri?.split('/').pop()
        return leftId === rightId
      }
    },
    sortOverride: (a, b) => {
      if (a.displayValue > b.displayValue) return 1
      if (a.displayValue < b.displayValue) return -1
      return 0
    },
    displayFormat: value => value?.displayValue,
    sanitizedValue: value => value,
    state: {
      component: CrossReferenceOptions,
      hasChanged: (type, value) => value !== type.gridUri,
      initialisation: (type) => type.gridUri,
      defaultValue: () => undefined,
      validate: (value) => value != null,
      attributesFrom: (value) => ({ attributes: value })
    },
    sampleValue: () => ({ uri: 'sampleUri', displayValue: 'sampleDisplayValue' })
  },
  references: {
    name: 'references',
    displayString: i18n.t('columnTypes.crossReferences'),
    typeIcon: 'mdi-table-multiple',
    cellComponent: GridCrossReferenceCell,
    readonlyCellComponent: RoGridReferencesCell,
    kanbanAttributeComponent: KanbanReferencesAttribute,
    defaultFormFieldType: 'multiSelectDropdown',
    filterConditions: [
      Conditions.AGAnyCondition,
      Conditions.AGNoneCondition,
      Conditions.AGAllCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    compareOverrides: {
      equals: (left, right) => {
        if (noValue(left) && noValue(right)) {
          return true
        }
        if ((left?.length ?? 0) !== (right?.length ?? 0)) {
          return false
        }
        for (let index = 0; index < left.length; index++) {
          if (left[index].uri !== right[index].uri) {
            return false
          }
        }
        return true
      },
      elementEquals: () => columnTypes.crossReference.compareOverrides.equals
    },
    displayFormat: value => value?.map(item => item.displayValue).join(', '),
    sanitizedValue: value => value,
    state: {
      component: CrossReferenceOptions,
      hasChanged: (type, value) => value !== type.gridUri,
      initialisation: (type) => type.gridUri,
      defaultValue: () => undefined,
      validate: (value) => value != null,
      attributesFrom: (value) => ({ attributes: value })
    },
    sampleValue: () => [{ uri: 'sampleUri', displayValue: 'sampleDisplayValue' }]
  },
  lookup: {
    name: 'lookup',
    readonly: true,
    displayString: i18n.t('columnTypes.lookup'),
    typeIcon: 'mdi-table-eye',
    defaultFormFieldType: undefined,
    state: {
      component: LookupOptions,
      hasChanged: (type, value) => type.lookupField !== value.lookupField || type.referenceField !== value.referenceField,
      initialisation: (type) => ({
        lookupField: type.lookupField,
        referenceField: type.referenceField,
      }),
      defaultValue: () => undefined,
      validate: (value) => value?.lookupField !== null || value?.referenceField !== null,
      attributesFrom: (value) => value
    },
    entityUpdatedCondition: {
      disabled: true
    }
  },
  sumup: {
    name: 'reducedLookupCollectionType',
    readonly: true,
    displayString: i18n.t('columnTypes.sumup'),
    typeIcon: 'mdi-sigma',
    defaultFormFieldType: undefined,
    state: {
      component: SumupOptions,
      hasChanged: (type, value) => type.lookupField !== value.lookupField || type.referenceField !== value.referenceField,
      initialisation: (type) => ({
        lookupField: type.lookupField,
        referencesField: type.referencesField,
      }),
      defaultValue: () => undefined,
      validate: (value) => value?.lookupField !== null || value?.referencesField !== null,
      attributesFrom: (value) => value
    }
  },
  attachments: {
    name: 'attachments',
    displayString: i18n.t('columnTypes.attachments'),
    typeIcon: 'mdi-file-outline',
    cellComponent: GridAttachmentCell,
    readonlyCellComponent: RoGridFilesCell,
    kanbanAttributeComponent: KanbanAttachmentsAttribute,
    defaultFormFieldType: 'filePicker',
    formFieldTypesOverride: ['filePicker', 'videoRecorder'],
    filterConditions: [
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    compareOverrides: {
      equals: (left, right) => {
        if (noValue(left) && noValue(right)) {
          return true
        }
        if ((left?.length ?? 0) !== (right?.length ?? 0)) {
          return false
        }
        for (let index = 0; index < left.length; index++) {
          if ((left[index].uri !== right[index].uri) || (left[index].name !== right[index].name)) {
            return false
          }
        }
        return true
      },
      elementEquals: () => (left, right) => {
        if (noValue(left) && noValue(right)) {
          return true
        }
        return (left?.uri === right?.uri) && (left.name === right.name)
      }
    },
    displayFormat: value => value?.map(item => item.name).join(', '),
    sanitizedValue: value => value,
    entityUpdatedCondition: {
      noPicker: true
    },
    sampleValue: () => [{ 
      url: 'httest://sampleImageUri.test',
      name: 'sampleImage.jpeg',
      type: 'image/jpeg',
      smallThumbnail: null,
      largeThumbnail: null,
    }]
  },
  geolocation: {
    name: 'geolocation',
    displayString: i18n.t('columnTypes.geolocation'),
    typeIcon: 'mdi-earth',
    cellComponent: GridGeolocationCell,
    readonlyCellComponent: RoGridTextCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    defaultFormFieldType: 'locationPicker',
    filterConditions: [
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    sortFunctions: [SORTING_FUNCTIONS.$distanceTo],
    compareOverrides: {
      equals: (left, right) => {
        if(noValue(left) && noValue(right)) {
          return true
        }
        if (left?.lat !== right?.lat || left?.lon !== right?.lon) {
          return false
        }
        return true
      }
    },
    displayFormat: value => value ? `${value.lat}, ${value.lon}` : '',
    sanitizedValue: value => {
      if (typeof value !== 'object' || noValue(value)) {
        return null
      }
      if (typeof value.lat !== 'number' || typeof value.lon !== 'number') {
        return null
      }
      return value
    },
    entityUpdatedCondition: {
      noPicker: true
    },
    sampleValue: () => ({ lat: 50.12, lon: 12.50 }),
  },
  address: {
    name: 'address',
    displayString: i18n.t('columnTypes.address'),
    typeIcon: 'mdi-home',
    cellComponent: GridAddressCell,
    readonlyCellComponent: RoGridTextCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    defaultFormFieldType: 'addressPicker',
    filterConditions: [
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    compareOverrides: {
      equals: (left, right) => {
        return isEqual(left, right)
      }
    },
    displayFormat: value => value ? new AGAddress(value).displayString : '',
    sanitizedValue: value => {
      if (typeof value !== 'object' || noValue(value)) {
        return null
      }
      return value
    },
    entityUpdatedCondition: {
      noPicker: true
    },
    sampleValue: () => ({ line1: 'sample', country: 'DE', postCode: '12345', city: 'Sample', geolocation: { lat: 50.93904122978723, lon: 6.933965785941976 } }),
  },
  createdBy: {
    name: 'createdby',
    readonly: true,
    displayString: i18n.t('columnTypes.createdBy'),
    typeIcon: 'mdi-account',
    cellComponent: CreatedByCell,
    readonlyCellComponent: CreatedByCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    filterConditions: [
      Conditions.AGIsActorCondition,
      Conditions.AGIsNotActorCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    filterExpressions: [
      Expressions.AGLoggedInUser
    ],
    disableSorting: true,
    compareOverrides: {
      equals: (left, right) => {
        if (noValue(left) && noValue(right)) {
          return true
        }
        return left?.id === right ?.id
      }
    },
    displayFormat: value => value?.displayValue || value?.name || value?.id || '',
    sanitizedValue: value => value,
    entityUpdatedCondition: {
      disabled: true
    }
  },
  createdAt: {
    name: 'createdat',
    readonly: true,
    displayString: i18n.t('columnTypes.createdAt'),
    typeIcon: 'mdi-clock-outline',
    cellComponent: RoGridTextCell,
    readonlyCellComponent: RoGridTextCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    filterConditions: [
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition,
      Conditions.AGLessCondition,
      Conditions.AGGreaterCondition
    ],
    filterOperandType: () => columnTypes.dateTime,
    filterConditionDisplayStringOverrides: {
      AGLessCondition: 'virtualGrid.filter.beforeCondition',
      AGGreaterCondition: 'virtualGrid.filter.afterCondition'
    },
    compareOverrides: {
      equals: (left, right) => {
        if (noValue(left) && noValue(right)) return true
        return typeof left === 'string' && moment(left).isSame(moment(right))
      },
      isLessThan: (left, right) => {
        return typeof left === 'string' && moment(left).isBefore(moment(right))
      },
      isGreaterThan: (left, right) => {
        return typeof left === 'string' && moment(left).isAfter(moment(right))
      }
    },
    displayFormat: value => value ? moment(value)?.format(DATETIME_DISPLAY_FORMAT) : '',
    sanitizedValue: value => value,
    entityUpdatedCondition: {
      disabled: true
    }
  },
  user: {
    name: 'user',
    displayString: i18n.t('columnTypes.user'),
    typeIcon: 'mdi-account-arrow-left',
    cellComponent: UserReferenceCell,
    readonlyCellComponent: CreatedByCell,
    kanbanAttributeComponent: KanbanUserReferenceAttribute,
    defaultFormFieldType: 'userSelect',
    filterConditions: [
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    filterExpressions: [
      Expressions.AGValueExpression,
      Expressions.AGLoggedInUser
    ],
    compareOverrides: {
      equals: (left, right) => {
        if (noValue(left) && noValue(right)) {
          return true
        }
        return left?.uri === right?.uri
      }
    },
    displayFormat: value => value?.displayValue || value?.name || value?.id || '',
    sanitizedValue: value => value
  },
  currency: {
    name: 'currency',
    displayString: i18n.t('columnTypes.currency'),
    typeIcon: 'mdi-cash',
    cellComponent: GridCurrencyCell,
    readonlyCellComponent: RoGridCurrencyCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    defaultFormFieldType: 'textfield',
    filterConditions: [
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGLessCondition,
      Conditions.AGGreaterCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    sanitizedValue: value => {
      const parsedFloat = parseFloat(value)
      return isNaN(parsedFloat) ? null : parsedFloat
    },
    sum: true,
    state: {
      component: CurrencyOptions,
      hasChanged: (type, value) => value !== type.currency,
      initialisation: (type) => type.currency,
      defaultValue: () => undefined,
      validate: (value) => value != null,
      attributesFrom: (value) => ({currency: value})
    },
    sampleValue: () => 0,
  },
  uri: {
    name: 'uri',
    displayString: i18n.t('columnTypes.uri'),
    typeIcon: 'mdi-link',
    cellComponent: GridUriCell,
    readonlyCellComponent: RoLinkCell,
    kanbanAttributeComponent: KanbanStringAttribute,
    defaultFormFieldType: 'textfield',
    sortOverride: (a, b) => {
      if (a.toLowerCase() > b.toLowerCase()) return 1
      if (a.toLowerCase() < b.toLowerCase()) return -1
      // When lowercase values are equal, use default sorting to have uppercase before lowercase
      if (a > b) return 1
      if (a < b) return -1
      return 0
    },
    filterConditions: [
      Conditions.AGSubstringCondition,
      Conditions.AGNotSubstringCondition,
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    sanitizedValue: value => {
      if (typeof value === 'string') {
        value = value.replace(/\s/g, '')
        if(value.length && !value.includes(':'))
        {
          value = `http://${value}`
        }
      } 
      return value
    },
    sampleValue: () => 'sampleUri',
  },
  email: {
    name: 'email',
    displayString: i18n.t('columnTypes.email'),
    typeIcon: 'mdi-at',
    cellComponent: GridEmailCell,
    readonlyCellComponent: RoLinkCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    defaultFormFieldType: 'textfield',
    sortOverride: (a, b) => {
      if (a.toLowerCase() > b.toLowerCase()) return 1
      if (a.toLowerCase() < b.toLowerCase()) return -1
      // When lowercase values are equal, use default sorting to have uppercase before lowercase
      if (a > b) return 1
      if (a < b) return -1
      return 0
    },
    filterConditions: [
      Conditions.AGSubstringCondition,
      Conditions.AGNotSubstringCondition,
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    sanitizedValue: value => value,
    sampleValue: () => 'sampleEmail@test.test',
  },
  phoneNumber: {
    name: 'phoneNumber',
    displayString: i18n.t('columnTypes.phoneNumber'),
    typeIcon: 'mdi-phone',
    cellComponent: GridPhoneNumberCell,
    readonlyCellComponent: RoLinkCell,
    kanbanAttributeComponent: BaseKanbanAttribute,
    defaultFormFieldType: 'textfield',
    sortOverride: (a, b) => {
      if (a.toLowerCase() > b.toLowerCase()) return 1
      if (a.toLowerCase() < b.toLowerCase()) return -1
      // When lowercase values are equal, use default sorting to have uppercase before lowercase
      if (a > b) return 1
      if (a < b) return -1
      return 0
    },
    filterConditions: [
      Conditions.AGSubstringCondition,
      Conditions.AGNotSubstringCondition,
      Conditions.AGEqualCondition,
      Conditions.AGNotEqualCondition,
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    sanitizedValue: value => !value ? null : value.replaceAll(/[^+\d]/g, ''),
    sampleValue: () => '+12345678',
  },
  signature: {
    name: 'signature',
    displayString: i18n.t('columnTypes.signature'),
    typeIcon: 'mdi-signature-freehand',
    cellComponent: GridSignatureCell,
    readonlyCellComponent: RoGridFilesCell,
    kanbanAttributeComponent: KanbanSignatureAttribute,
    defaultFormFieldType: 'filePicker',
    filterConditions: [
      Conditions.AGIsEmptyCondition,
      Conditions.AGIsNotEmptyCondition
    ],
    compareOverrides: {
      equals: (left, right) => {
        if (noValue(left) && noValue(right)) {
          return true
        }
        return (left?.url === right?.url) && (left?.name === right?.name)
      }
    },
    displayFormat: value => value?.name,
    sanitizedValue: value => value,
    entityUpdatedCondition: {
      noPicker: true
    }
  },
  formula: {
    name: 'formula',
    readonly: true,
    displayString: i18n.t('columnTypes.formula'),
    typeIcon: 'mdi-function',
    defaultFormFieldType: undefined,
    readonlyCellComponent: GridFormulaCell,
    state: {
      component: FormulaOptions,
      hasChanged: (type, value) => type.expression !== value.expression || type.valueType?.name !== value.valueType?.name,
      initialisation: (type) => ({
        expression: type.expression,
        valueType: type.valueType,
      }),
      defaultValue: () => undefined,
      validate: (value) => value?.expression && value?.valueType?.name !== null,
      attributesFrom: (value) => value
    },
    entityUpdatedCondition: {
      disabled: true
    }
  }
}

export const formats = [columnTypes.dateTime, columnTypes.date]

export function typeFor(name, delegateType) {
  const type = Object.values(columnTypes).find(
    type => type.name == name
  )

  const delegateOfDelegate = delegateType?.lookupType ?? delegateType?.reducedType ?? delegateType?.valueType
  // Lookup takes its behaviour from the type of the looked up field
  if (name === columnTypes.lookup.name) {
    const delegateColumnType = typeFor(delegateType.name, delegateOfDelegate)
    const lookupDefinition = {
      delegateColumnType,
      ...delegateColumnType,
      ...type
    }
    // Use looked up field type as filter operand
    lookupDefinition.filterOperandType = lookupDefinition.filterOperandType ?? (() => delegateColumnType)
    // Lookup is read-only use read-only cell
    lookupDefinition.cellComponent = lookupDefinition.readonlyCellComponent
    return lookupDefinition
  }

  if (name === columnTypes.sumup.name) {
    const delegateColumnType = typeFor(delegateType.name, delegateOfDelegate)
    const sumupDefinition = {
      delegateColumnType,
      ...delegateColumnType,
      ...type
    }
    // Use sum result field type as filter operand
    sumupDefinition.filterOperandType = () => sumupDefinition.filterOperandType ?? (() => delegateColumnType)
    // sumup is read-only use read-only cell
    sumupDefinition.cellComponent = sumupDefinition.readonlyCellComponent
    return sumupDefinition
  }

  if (name === columnTypes.formula.name) {
    const delegateColumnType = typeFor(delegateType.name)
    const formulaDefinition = {
      delegateColumnType,
      ...delegateColumnType,
      ...type
    }
    // Use formula result field type as filter operand
    formulaDefinition.filterOperandType = () => formulaDefinition.filterOperandType ?? (() => delegateColumnType)
    // formula is read-only use read-only cell
    formulaDefinition.cellComponent = formulaDefinition.readonlyCellComponent
    formulaDefinition.displayFormat = value => delegateColumnType.displayFormat ? delegateColumnType.displayFormat(value?.value) : value?.value
    formulaDefinition.filterOperandType = () => delegateColumnType
    return formulaDefinition
  }
  return type
}